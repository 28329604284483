import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid'; // To generate unique session IDs
import { retrieveData, STORAGE_CONSTANTS, storeData } from '../../../utils';
import { setDeviceId, setSessionId, store, useAppDispatch, useAppSelector } from '@shalina-app/shared';

const SESSION_TIMEOUT = 30 * 60 * 1000; // 30 minutes of inactivity for session expiry

interface UseSessionReturn {
  deviceId: null | string;
  sessionId: null | string;
  updateLastActiveTime: () => void;
  resetSessionMetaData: () => void;
}

const useSession = (): UseSessionReturn => {
  const { sessionId, deviceId } = useAppSelector(state => state.partialRegistration);
  const [lastActiveTime, setLastActiveTime] = useState<number>(Date.now());
  const dispatch = useAppDispatch();

  // Initialize session
  useEffect(() => {
    const initializeSession = () => {
      try {
        const storedSessionId = retrieveData(STORAGE_CONSTANTS.SESSION_ID);
        const storedDeviceId =
          retrieveData(STORAGE_CONSTANTS.DEVICE_ID) || uuidv4(); // Generate if not present
        const storedLastActiveTime = retrieveData(STORAGE_CONSTANTS.LAST_ACTIVE_TIME);

        const now = Date.now();

        // Check if session is expired
        if (
          !storedSessionId ||
          (storedLastActiveTime && now - Number(storedLastActiveTime) > SESSION_TIMEOUT)
        ) {
          const newSessionId = uuidv4();
          dispatch(setSessionId(newSessionId));
          storeData(STORAGE_CONSTANTS.SESSION_ID, newSessionId);
        } else {
          dispatch(setSessionId(storedSessionId));
        }

        // Always store the deviceId
        dispatch(setDeviceId(storedDeviceId));
        storeData(STORAGE_CONSTANTS.DEVICE_ID, storedDeviceId);

        // Update last active time
        setLastActiveTime(now);
        storeData(STORAGE_CONSTANTS.LAST_ACTIVE_TIME, now.toString());
      } catch (error) {
        console.error('Error initializing session:', error);
      }
    };

    initializeSession();
  }, []);

  // Reset session metadata
  const resetSessionMetaData = () => {
    const newSessionId = uuidv4();
    dispatch(setSessionId(newSessionId));
    storeData(STORAGE_CONSTANTS.SESSION_ID, newSessionId);
  };

  // Update last active time on user activity
  const updateLastActiveTime = () => {
    const now = Date.now();
    setLastActiveTime(now);
    storeData(STORAGE_CONSTANTS.LAST_ACTIVE_TIME, now.toString());
  };

  // Monitor app visibility to detect inactivity
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        const now = Date.now();
        const storedLastActiveTime = retrieveData(STORAGE_CONSTANTS.LAST_ACTIVE_TIME);

        if (storedLastActiveTime && now - Number(storedLastActiveTime) > SESSION_TIMEOUT) {
          // Expire the session and create a new one
          const newSessionId = uuidv4();
          setSessionId(newSessionId);
          storeData(STORAGE_CONSTANTS.SESSION_ID, newSessionId);
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return { 
    deviceId: deviceId || retrieveData(STORAGE_CONSTANTS.DEVICE_ID), 
    sessionId: sessionId || retrieveData(STORAGE_CONSTANTS.SESSION_ID), 
    updateLastActiveTime, 
    resetSessionMetaData 
  };
};

export default useSession;
