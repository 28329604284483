import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { storeAccessToken } from '../../../utils';

export const SamlRedirector = () => {
  const location = useLocation();
  const params = location?.search;
  const urlParams = new URLSearchParams(params);
  const tokenParam: any = urlParams.get('token');
  const urlParam: any = urlParams.get('url');
  console.log('token param ', tokenParam);
  console.log('url param ', urlParam);
  const decodedTokenParam = decodeURIComponent(tokenParam);
  console.log('decoded token param ', decodedTokenParam);
  storeAccessToken(decodedTokenParam);
  const decodedUrlParam = decodeURIComponent(urlParam);
  console.log('decoded url param ', decodedUrlParam);

  useEffect(() => {
    if (decodedUrlParam) {
      window.location.href = decodedUrlParam;
      // window.location.href = 'http://localhost:3000/sign-in?returnData="cgdf"'
    }
  }, [decodedUrlParam]);

  return <></>;
};
