"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setDeviceId = exports.setSessionId = exports.updateSessionMetaData = exports.resetKPI = exports.setOnlineStatus = exports.updateCurrentStep = exports.updateStatus = exports.logError = exports.updateUserInput = exports.initializeSession = void 0;
// File: src/store/slices/kpiSlice.js
const toolkit_1 = require("@reduxjs/toolkit");
const partial_registration_interface_1 = require("./partial-registration.interface");
const initialState = {
    sessionId: null,
    deviceId: null,
    userInputData: {},
    errorLog: [],
    status: partial_registration_interface_1.PARTIAL_REGISTRATION_STATUSES.REGISTRATION_INCOMPLETE,
    timestamp: '',
    currentStep: 'registration',
    isOnline: true,
    shouldResetSession: false,
    hasSessionInitialized: false,
};
const partialRegistrationSlice = (0, toolkit_1.createSlice)({
    name: partial_registration_interface_1.PARTIAL_REGISTRATION,
    initialState,
    reducers: {
        // Initialize session and device details
        initializeSession: (state, action) => {
            state.sessionId = action.payload.sessionId;
            state.deviceId = action.payload.deviceId;
            if (action.payload.sessionId && action.payload.deviceId) {
                state.hasSessionInitialized = true;
            }
            state.timestamp = new Date().toISOString();
        },
        // Update user input data
        updateUserInput: (state, action) => {
            state.userInputData = Object.assign(Object.assign({}, state.userInputData), action.payload);
            state.timestamp = new Date().toISOString();
        },
        // Log an error with details
        logError: (state, action) => {
            state.errorLog.push(Object.assign(Object.assign({}, action.payload), { timestamp: new Date().toISOString() }));
            state.status = partial_registration_interface_1.PARTIAL_REGISTRATION_STATUSES.REGISTRATION_INCOMPLETE; // Mark status as incomplete on error
            state.timestamp = new Date().toISOString();
        },
        // Update registration status
        updateStatus: (state, action) => {
            state.status = action.payload.status;
            state.timestamp = new Date().toISOString();
        },
        // Update user's current step in the registration process
        updateCurrentStep: (state, action) => {
            state.currentStep = action.payload.step;
            state.timestamp = new Date().toISOString();
        },
        // Set online/offline status
        setOnlineStatus: (state, action) => {
            state.isOnline = action.payload.isOnline;
            state.timestamp = new Date().toISOString();
        },
        // Reset KPI to the initial state
        resetKPI: () => initialState,
        updateSessionMetaData: (state, action) => {
            state.sessionId = action.payload;
            state.deviceId = action.payload;
            state.timestamp = new Date().toISOString();
        },
        setSessionId: (state, action) => {
            state.sessionId = action.payload;
        },
        setDeviceId: (state, action) => {
            state.deviceId = action.payload;
        },
    },
});
_a = partialRegistrationSlice.actions, exports.initializeSession = _a.initializeSession, exports.updateUserInput = _a.updateUserInput, exports.logError = _a.logError, exports.updateStatus = _a.updateStatus, exports.updateCurrentStep = _a.updateCurrentStep, exports.setOnlineStatus = _a.setOnlineStatus, exports.resetKPI = _a.resetKPI, exports.updateSessionMetaData = _a.updateSessionMetaData, exports.setSessionId = _a.setSessionId, exports.setDeviceId = _a.setDeviceId;
exports.default = partialRegistrationSlice.reducer;
