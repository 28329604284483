"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ERROR_TYPE = exports.PARTIAL_REGISTRATION_STATUSES = exports.PARTIAL_REGISTRATION = void 0;
exports.PARTIAL_REGISTRATION = 'partialRegistration';
exports.PARTIAL_REGISTRATION_STATUSES = {
    REGISTRATION_INCOMPLETE: 'Registration Incomplete',
    OTP_VERIFICATION_PENDING: 'Otp Verification Pending',
    REGISTRATION_SUCCESS: 'Registration Success'
};
exports.ERROR_TYPE = {
    API_ERROR: 'API Error',
    CRASH: 'Crash',
    API_TIMEOUT: 'API Timeout',
    SERVER_ERROR: 'Server Error',
    CLIENT_ERROR: 'Client Error',
    UI_LEVEL_ERROR: 'UI_Error',
    APP_DISENGAGEMENT: 'App Disengagement',
    USER_OFFLINE: 'User Offline',
    LOW_NETWORK_CONNECTIVITY: 'Low Network Connectivity',
    IN_APP_DISENGAGEMENT: 'In App Disengagement'
};
