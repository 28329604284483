import { useState, useEffect, useRef } from 'react';
import useSession from './useSession'; // Replace with your session hook
import { removedFromStore, retrieveData, STORAGE_CONSTANTS, storeData } from '../../../utils';
import { checkFields, CUSTOM_ANALYTICS_EVENT_NAMES, ERROR_TYPE, PARTIAL_REGISTRATION_STATUSES, PlatformNames } from '@shalina-app/shared';
import { appInsights } from '../../../applicationInsight';

interface PartialData {
  [key: string]: any; // Define your specific structure here
}

interface OfflineHandlerHook {
  hasGoneOffline: boolean;
  isNetworkPoor: boolean;
  savePartialData: (data: PartialData) => Promise<void>;
  restorePartialData: () => Promise<PartialData | null>;
  sendKPI: (sessionId: string | null, deviceId: string | null) => Promise<void>;
}

const useOfflineHandler = (partialData: PartialData, sessionId: string | null, deviceId: string | null, kpiStatus?: string | undefined | null): OfflineHandlerHook => {
  const [hasGoneOffline, setHasGoneOffline] = useState(false);
  const [isNetworkPoor, setIsNetworkPoor] = useState(false);
  const { resetSessionMetaData } = useSession();
  const prevOfflineState = useRef<boolean>(false);

  useEffect(() => {
    const handleOnlineStatus = () => setHasGoneOffline(false);
    const handleOfflineStatus = () => setHasGoneOffline(true);

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOfflineStatus);

    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOfflineStatus);
    };
  }, []);

  useEffect(() => {
    // Check for state transitions
    if (prevOfflineState.current !== hasGoneOffline) {
      prevOfflineState.current = hasGoneOffline; // Update previous state
      savePartialData(partialData); // Call only on state change
    }
  }, [hasGoneOffline]);

  // Save partial registration data to localStorage
  const savePartialData = async (data: PartialData): Promise<void> => {
    try {
      if (data && checkFields(data)) {
        console.log('Partial registration data saved locally.', data);
        storeData(STORAGE_CONSTANTS.USER_PARTIAL_REGISTRATION,
          JSON.stringify({ ...data, type: ERROR_TYPE.USER_OFFLINE })
        );
      }
    } catch (error) {
      console.error('Error saving partial data:', error);
    }
  };

  // Restore partial registration data from localStorage
  const restorePartialData = async (): Promise<PartialData | null> => {
    try {
      const savedData = retrieveData(STORAGE_CONSTANTS.USER_PARTIAL_REGISTRATION);
      return savedData ? JSON.parse(savedData) : null;
    } catch (error) {
      console.error('Error restoring partial data:', error);
      return null;
    }
  };

  // Simulate sending data to KPI
  const sendKPI = async (sessionId: string | null, deviceId: string | null): Promise<void> => {
    try {
      const savedData = retrieveData(STORAGE_CONSTANTS.USER_PARTIAL_REGISTRATION);
      if (savedData && sessionId && deviceId) {
        const parsedData = JSON.parse(savedData);
        await resetSessionMetaData();
        await logDataToKPI(parsedData);
      }
    } catch (error) {
      console.error('Error sending data to KPI:', error);
    }
  };

  const logDataToKPI = async (parsedData: PartialData) => {
    removedFromStore(STORAGE_CONSTANTS.USER_PARTIAL_REGISTRATION); // Clear stored data on success
    const payload = {
      userEmail: parsedData.email ?? '',
      userMobile: parsedData.mobileNumber ?? '',
      sessionId,
      deviceId,
      userInputData: parsedData,
      status: kpiStatus ? kpiStatus : PARTIAL_REGISTRATION_STATUSES.REGISTRATION_INCOMPLETE,
      errorLog: {
        errorType: parsedData.type ?? ERROR_TYPE.USER_OFFLINE,
        message: 'Something went wrong',
        sessionId,
        deviceId,
        timestamp: new Date().toISOString(),
      },
      currentStep: parsedData.currentStep ?? '',
      platform: PlatformNames.WEB,
      timestamp: new Date().toISOString(),
    }
    console.log('LOG:: going to log to KPI in offline handler', payload)
    appInsights.trackEvent(
      { name: CUSTOM_ANALYTICS_EVENT_NAMES.USER_PARTIAL_REGISTRATION },
      {
        userEmail: parsedData.email ?? '',
        userMobile: parsedData.mobileNumber ?? '',
        sessionId,
        deviceId,
        userInputData: parsedData,
        status: kpiStatus ? kpiStatus : PARTIAL_REGISTRATION_STATUSES.REGISTRATION_INCOMPLETE,
        errorLog: {
          errorType: parsedData.type ?? ERROR_TYPE.USER_OFFLINE,
          message: 'Something went wrong',
          sessionId,
          deviceId,
          timestamp: new Date().toISOString(),
        },
        currentStep: parsedData.currentStep ?? '',
        platform: PlatformNames.WEB,
        timestamp: new Date().toISOString(),
      }
    );
  };

  return {
    hasGoneOffline,
    isNetworkPoor,
    savePartialData,
    restorePartialData,
    sendKPI,
  };
};

export default useOfflineHandler;
