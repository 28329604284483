"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkFields = exports.generateQueryString = exports.convertArrayOfObjectToString = exports.getTargetMilestone = exports.filterOptionsParams = exports.getProfileCompletionPercentage = exports.getImageSource = exports.removeDuplicates = void 0;
const api_1 = require("../../api");
const imageUtils_1 = require("../../imageUtils");
const removeDuplicates = (data, key) => {
    const uniqueMap = new Map();
    return data === null || data === void 0 ? void 0 : data.filter((item) => {
        if (item && typeof item === "object" && item[key] !== undefined) {
            // Exclude null values and empty arrays
            if (item[key] !== null &&
                !(Array.isArray(item[key]) && item[key].length === 0)) {
                if (!uniqueMap.has(item[key])) {
                    uniqueMap === null || uniqueMap === void 0 ? void 0 : uniqueMap.set(item[key], true);
                    return true;
                }
            }
        }
        return false;
    });
};
exports.removeDuplicates = removeDuplicates;
const getImageSource = (imageUrl, fallbackImage, customUrl) => {
    let baseUrl = api_1.IMAGE_BASE_URL;
    if (imageUrl && imageUrl !== null) {
        if (customUrl) {
            return { uri: customUrl };
        }
        if (imageUrl === null || imageUrl === void 0 ? void 0 : imageUrl.startsWith('/')) {
            baseUrl = api_1.IMAGE_BASE_URL_WITHOUT_SLASH;
        }
        if (imageUrl === null || imageUrl === void 0 ? void 0 : imageUrl.startsWith('https')) {
            return { uri: `${imageUrl}` };
        }
        return { uri: `${baseUrl}${imageUrl}` };
    }
    else {
        if (fallbackImage)
            return fallbackImage;
        return imageUtils_1.ImageConstant.SOURCE.cardDefaultThumbnail;
    }
};
exports.getImageSource = getImageSource;
const getProfileCompletionPercentage = (user) => {
    var _a, _b, _c, _d;
    let percentage = 0;
    const signUpDone = user ? true : false;
    const firstProfileSetupDone = ((_a = user === null || user === void 0 ? void 0 : user.country) === null || _a === void 0 ? void 0 : _a.length) > 0 &&
        ((_b = user === null || user === void 0 ? void 0 : user.specialization) === null || _b === void 0 ? void 0 : _b.length) > 0 &&
        ((_c = user === null || user === void 0 ? void 0 : user.qualification) === null || _c === void 0 ? void 0 : _c.length) > 0
        ? true
        : false;
    const InterestSetupDone = ((_d = user === null || user === void 0 ? void 0 : user.areaOfInterest) === null || _d === void 0 ? void 0 : _d.length) > 0 ? true : false;
    const profilePictureDone = (user === null || user === void 0 ? void 0 : user.userPicture) ? true : false;
    if (signUpDone) {
        percentage = percentage + 50;
    }
    if (firstProfileSetupDone) {
        percentage = percentage + 30;
    }
    if (InterestSetupDone) {
        percentage = percentage + 10;
    }
    if (profilePictureDone) {
        percentage = percentage + 10;
    }
    return percentage;
};
exports.getProfileCompletionPercentage = getProfileCompletionPercentage;
const filterOptionsParams = (filterOptions) => {
    if (!filterOptions)
        return "";
    const queryString = Object.keys(filterOptions)
        .filter((key) => filterOptions[key] !== "" &&
        filterOptions[key] !== undefined)
        .map((key) => {
        const value = filterOptions[key];
        if (key === "contentType") {
            return `content_type=${value}`;
        }
        else {
            return `${encodeURIComponent(key)}=${value}`;
        }
    })
        .join("&");
    return queryString ? `?${queryString}` : "";
};
exports.filterOptionsParams = filterOptionsParams;
const getTargetMilestone = (milestone, masterData) => {
    var _a, _b;
    let response = {
        achievedMilestone: {},
        nextMilestone: {},
    };
    const result = (_a = masterData === null || masterData === void 0 ? void 0 : masterData.slice()) === null || _a === void 0 ? void 0 : _a.sort((a, b) => (a === null || a === void 0 ? void 0 : a.points) - (b === null || b === void 0 ? void 0 : b.points));
    const lastElement = (_b = result === null || result === void 0 ? void 0 : result.slice(-1)) === null || _b === void 0 ? void 0 : _b[0];
    if ((lastElement === null || lastElement === void 0 ? void 0 : lastElement.tid) === (milestone === null || milestone === void 0 ? void 0 : milestone.tid)) {
        const achieve = result === null || result === void 0 ? void 0 : result.filter((item) => (item === null || item === void 0 ? void 0 : item.points) < (milestone === null || milestone === void 0 ? void 0 : milestone.points));
        response.achievedMilestone = achieve;
        response.nextMilestone = [milestone];
    }
    else {
        const next = result === null || result === void 0 ? void 0 : result.filter((item) => (item === null || item === void 0 ? void 0 : item.points) > (milestone === null || milestone === void 0 ? void 0 : milestone.points));
        const achieve = result === null || result === void 0 ? void 0 : result.filter((item) => (item === null || item === void 0 ? void 0 : item.points) <= (milestone === null || milestone === void 0 ? void 0 : milestone.points));
        response.achievedMilestone = achieve;
        response.nextMilestone = next;
    }
    return response;
};
exports.getTargetMilestone = getTargetMilestone;
const convertArrayOfObjectToString = (array, parameter) => {
    var _a;
    const convertedString = (_a = array === null || array === void 0 ? void 0 : array.map((item) => {
        return item === null || item === void 0 ? void 0 : item[parameter];
    })) === null || _a === void 0 ? void 0 : _a.toString();
    const find = ",";
    const replaceStr = new RegExp(find, "g");
    const newStr = convertedString === null || convertedString === void 0 ? void 0 : convertedString.replace(replaceStr, ", ");
    return newStr || "";
};
exports.convertArrayOfObjectToString = convertArrayOfObjectToString;
const generateQueryString = (params) => {
    var _a;
    // Check if params is not an empty object
    if (((_a = Object.keys(params)) === null || _a === void 0 ? void 0 : _a.length) === 0) {
        return '';
    }
    const queryString = Object.keys(params)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
        .join('&');
    return '?' + queryString;
};
exports.generateQueryString = generateQueryString;
const FIELDS_TO_INCLUDE = ['firstName', 'lastName', 'mobileNumber', 'email', 'cityOrHospital', 'verificationCode', 'termsAndConditionChecked'];
const FIELDS_TO_NOT_INCLUDE = ['sessionId', 'deviceId', 'countryCode', 'timestamp'];
const checkFields = (obj) => {
    const filteredKeys = Object.keys(obj).filter(key => !FIELDS_TO_NOT_INCLUDE.includes(key));
    return FIELDS_TO_INCLUDE.some(field => filteredKeys.includes(field));
};
exports.checkFields = checkFields;
