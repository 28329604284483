import {
  BLOB_BASE_URL,
  BLOB_FILES_CONTAINER_PARAMS,
  CUSTOM_ANALYTICS_EVENT_NAMES,
  logEvent,
  PlatformNames,
} from '@shalina-app/shared';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { appInsights } from '../../../applicationInsight';
import { detectAndroidDevice, detectIosDevice } from '../../../utils';

export const AppStoreRedirector = () => {
  const location = useLocation();
  let hasLogEvent = false;
  useEffect(() => {
    const params = location?.search;
    const urlParams = new URLSearchParams(params);
    const utmSource = urlParams.get("utm_source"); // Get utm_source from URL
    let platform = PlatformNames.WEB;
    if (utmSource && !hasLogEvent) {
      console.log("UTM Source:", utmSource);
      if (detectIosDevice() || detectAndroidDevice()) {
        platform= detectIosDevice() ? PlatformNames.IOS : PlatformNames.ANDROID;
      } else {
        platform = PlatformNames.WEB;
      }
      logEvent(
        appInsights,
        'custom',
        CUSTOM_ANALYTICS_EVENT_NAMES.UTM_TRACKING,
        {
          utmSource: utmSource,
          platform: platform,
          timestamp: new Date().toISOString()
        }
      );
      hasLogEvent = true;
    }    
  }, []);

  const url = `${BLOB_BASE_URL}/config/qrcode.html${BLOB_FILES_CONTAINER_PARAMS}`;
  useEffect(() => {
    if (url) {
      window.location.href = url;
    }
  }, [url]);

  return <></>;
};
