import { useEffect } from 'react';
import { removedFromStore, retrieveData, STORAGE_CONSTANTS, storeData } from '../../../utils';
import useSession from './useSession';
import { checkFields, CUSTOM_ANALYTICS_EVENT_NAMES, ERROR_TYPE, PARTIAL_REGISTRATION_STATUSES, PlatformNames } from '@shalina-app/shared';
import { appInsights } from '../../../applicationInsight';

interface PartialData {
  [key: string]: any; // Define the structure of your partial data
}

const ENCRYPTION_KEYS = {
  PARTIAL_REGISTRATION_DATA: 'PARTIAL_REGISTRATION_DATA',
};

const useAppLifecycleAndNetwork = (partialData: PartialData, sessionId: string | null, deviceId: string | null, kpiStatus?: string | undefined | null) => {
  const { resetSessionMetaData } = useSession();
  useEffect(() => {
    const savePartialData = async () => {
      if(partialData && checkFields(partialData)){
        console.log('PR:: saving data on app disengagement', partialData, kpiStatus);
        storeData(
          STORAGE_CONSTANTS.USER_PARTIAL_REGISTRATION,
          JSON.stringify({ ...partialData, type: ERROR_TYPE.APP_DISENGAGEMENT })
        );
      }
    };

    // Simulate sending data to KPI
  const sendKPI = async (sessionId: string | null, deviceId: string | null): Promise<void> => {
    try {
      const savedData = await restorePartialData();
      if (savedData && sessionId && deviceId) {
        await resetSessionMetaData();
        await logDataToKPI(savedData);
      }
    } catch (error) {
      console.error('Error sending data to KPI:', error);
    }
  };

  const logDataToKPI = async (parsedData: PartialData) => {
    removedFromStore(STORAGE_CONSTANTS.USER_PARTIAL_REGISTRATION); // Clear stored data on success
    const payload = {
      userEmail: parsedData.email ?? '',
      userMobile: parsedData.mobileNumber ?? '',
      sessionId,
      deviceId,
      userInputData: parsedData,
      status: kpiStatus ? kpiStatus : PARTIAL_REGISTRATION_STATUSES.REGISTRATION_INCOMPLETE,
      errorLog: {
        errorType: parsedData.type ?? ERROR_TYPE.APP_DISENGAGEMENT,
        message: 'Something went wrong',
        sessionId,
        deviceId,
        timestamp: new Date().toISOString(),
      },
      currentStep: parsedData.currentStep ?? '',
      platform: PlatformNames.WEB,
      timestamp: new Date().toISOString(),
    }
    console.log('LOG:: going to log to KPI', payload)
    appInsights.trackEvent(
      { name: CUSTOM_ANALYTICS_EVENT_NAMES.USER_PARTIAL_REGISTRATION},
      {
        userEmail: parsedData.email ?? '',
        userMobile: parsedData.mobileNumber ?? '',
        sessionId,
        deviceId,
        userInputData: parsedData,
        status: kpiStatus ? kpiStatus : PARTIAL_REGISTRATION_STATUSES.REGISTRATION_INCOMPLETE,
        errorLog: {
          errorType: parsedData.type ?? ERROR_TYPE.USER_OFFLINE,
          message: 'Something went wrong',
          sessionId,
          deviceId,
          timestamp: new Date().toISOString(),
        },
        currentStep: parsedData.currentStep ?? '',
        platform: PlatformNames.WEB,
        timestamp: new Date().toISOString(),
      }
    );
  };

  const restorePartialData = async (): Promise<any | null> => {
    try {
      const data = retrieveData(STORAGE_CONSTANTS.USER_PARTIAL_REGISTRATION);
      return data ? JSON.parse(data) : null;
    } catch (error) {
      console.error('Error restoring partial data:', error);
      return null;
    }
  };

    // Handle tab visibility changes (e.g., user switches tabs or backgrounds app)
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        console.log('LOG:: saving partial data on visibility change')
        savePartialData();
      }else if (
        document.visibilityState === 'visible' && 
        sessionId && 
        deviceId
      ) {
        console.log('Visibility restored');
        sendKPI(sessionId, deviceId);
      }
    };

    // Handle app close or reload
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      console.log('PR:: saving data on reload')
      savePartialData();
      event.preventDefault();
      event.returnValue = ''; // Some browsers require this for the confirmation dialog
    };

    // Add event listeners
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup event listeners on unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [partialData, sessionId, deviceId, kpiStatus]);
};

export default useAppLifecycleAndNetwork;
