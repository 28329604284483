import {
  AppConstant,
  getCountryCode,
  sendOtpApi,
  translate,
  useAppDispatch,
  useAppSelector,
  ImageConstant,
  SEND_OTP_ACTION_TYPE,
  getContactModeLabelById,
  getContactModes,
  OTP_VERIFICATION_MEDIUM,
  redirectorApi,
  ANALYTICS_PAGE_NAMES,
  logEvent,
} from '@shalina-app/shared';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { pageNames } from '../../../../app.constants';
import { appInsights } from '../../../../applicationInsight';
import {
  ButtonComponent,
  CircularLoader,
  HyperLink,
  Input,
  MobileInput,
  Modal,
  RadioGroupComponent,
  Toast,
} from '../../../../components';
import { URLConstant } from '../../../../routes';
import {
  countryCodeValidation,
  emailValidation,
  eventType,
  mobileStartZeroValidation,
  mobileValidation,
  radioGroupValidation,
  useNavigatePageUtils,
} from '../../../../utils';
import { HorizontalDivider } from '../../components';
import styles from './signIn.module.scss';

export const SignInPage: React.FC<any> = () => {
  const ref: any = useRef(null);
  const navigatePageUtils = useNavigatePageUtils();
  const { urls } = URLConstant;
  const t = translate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const statePathName = location?.state?.pathName;
  const rssContentData = location?.state?.rssContentData;

  const params = location?.search;
  const returnData = params?.split('?')?.[1]?.split('returnData=')?.[1];

  const countryList = useAppSelector(getCountryCode);
  const contactModes = useAppSelector(getContactModes);

  const authenticationLoading = useAppSelector(
    (state) => state?.authentication?.isLoading
  );

  const samlAuthenticalLoading = useAppSelector(
    (state) => state?.authentication?.samlAuthentication?.isLoading
  );
  // const commonLoading = useAppSelector((state) => state?.common?.isLoading);
  // const mobileLength = useAppSelector(getMaxMobileLength);

  const [emailId, setEmailId] = useState('');
  const [selectedVerificationMedium, setSelectedVerificationMedium] =
    useState('');
  const [nextCtaEnable, setNextCtaEnable] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSubMessage, setToastSubMessage] = useState('');
  const [mobileLength, setMobileLength] = useState('');
  const [samlLoad, setSamlLoad] = useState(false);
  const [countryFlag, setCountryFlag] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCtaAction, setModalCtaAction] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    logEvent(appInsights, 'pageView', ANALYTICS_PAGE_NAMES.SIGN_IN);
    loadApi();
  }, []);

  useEffect(() => {
    if (returnData) {
      setSamlLoad(true);
    }
  }, [returnData]);

  useEffect(() => {
    if (samlLoad) {
      loadSamlApi();
      setSamlLoad(false);
    }
  }, [samlLoad]);

  const loadSamlApi = async () => {
    const payload = {
      requestData: returnData,
    };
    const response: any = await dispatch(redirectorApi(payload)).unwrap();

    if (response && response?.data?.sessionInitiatorUrl) {
      window.open(response?.data?.sessionInitiatorUrl, '_self');
    }
  };

  useEffect(() => {
    if (contactModes && !selectedVerificationMedium) {
      const whatsAppDefault = contactModes?.find(
        (mode: { label: string; value: string }) =>
          mode?.label?.includes('Whatsapp')
      );
      setSelectedVerificationMedium(whatsAppDefault?.value);
    }
  }, [contactModes]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref?.current && !ref?.current?.contains(event?.target)) {
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (countryCode) {
      const country = countryList?.find(
        (item: any) => item?.value === countryCode
      );
      setMobileLength(country?.mobileLength);
      setCountryFlag(country?.icons);
    }
  }, [countryList, countryCode]);

  useEffect(() => {
    if (countryList && countryList?.length > 0) {
      const initialCountryCode = countryList?.[0]?.value;
      setCountryCode(initialCountryCode);
    }
  }, [countryList]);

  const loadApi = async () => {
    // await dispatch(getCountryCodeList());
  };

  const selectedModeLabel = useAppSelector(
    getContactModeLabelById(selectedVerificationMedium)
  );

  const handleSignIn = async () => {
    const req = {
      payload: {
        countryCode: countryCode,
        mobileNumber: mobileNumber,
        emailId: emailId,
        mode: selectedModeLabel?.toLowerCase(),
      },
      params: SEND_OTP_ACTION_TYPE.SIGN_IN_ACTION,
    };
    const response = await dispatch(sendOtpApi(req)).unwrap();

    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200
    ) {
      setShowToast(true);
      setIsSuccess(true);
      setToastMessage(t(response?.message));
      setNextCtaEnable(false);
      const navigateDetails = {
        stateParams: {
          pathName: statePathName,
          rssContentData: rssContentData,
          sourcePage: pageNames.SignIn,
          nextCta: t(AppConstant.AUTHENTICATION_PAGE.SIGN_IN_CTA),
          formData: {
            mobile: mobileNumber,
            country: countryCode,
            email: emailId,
            mode: selectedModeLabel?.toLowerCase(),
            otpSent:
              selectedModeLabel?.toLowerCase() ===
              OTP_VERIFICATION_MEDIUM.EMAIL_VERIFICATION_MEDIUM
                ? emailId
                : `${countryCode} ${mobileNumber}`,
          },
        },
        isDynamicUrl: false,
      };
      navigatePageUtils(urls.verificationPage, navigateDetails);
    } else if (response && response?.status === 'ERROR') {
      setNextCtaEnable(false);
      // setShowToast(true);
      // setIsSuccess(false);
      if (response && response?.response?.headers) {
        const contentType = response?.response?.headers["content-type"];
        if (contentType && contentType.includes("text/html")) {
          setIsSuccess(true);
          setNextCtaEnable(false);
          const navigateDetails = {
            stateParams: {
              pathName: statePathName,
              rssContentData: rssContentData,
              sourcePage: pageNames.SignIn,
              nextCta: t(AppConstant.AUTHENTICATION_PAGE.SIGN_IN_CTA),
              formData: {
                mobile: mobileNumber,
                country: countryCode,
                email: emailId,
                mode: selectedModeLabel?.toLowerCase(),
                otpSent:
                  selectedModeLabel?.toLowerCase() ===
                  OTP_VERIFICATION_MEDIUM.EMAIL_VERIFICATION_MEDIUM
                    ? emailId
                    : `${countryCode} ${mobileNumber}`,
              },
            },
            isDynamicUrl: false,
          };
          navigatePageUtils(urls.verificationPage, navigateDetails);
        }
      } else if (response?.message === "OTP_SEND_FAILED") {
        setIsSuccess(true);
        setNextCtaEnable(false);
        const navigateDetails = {
          stateParams: {
            pathName: statePathName,
            rssContentData: rssContentData,
            sourcePage: pageNames.SignIn,
            nextCta: t(AppConstant.AUTHENTICATION_PAGE.SIGN_IN_CTA),
            formData: {
              mobile: mobileNumber,
              country: countryCode,
              email: emailId,
              mode: selectedModeLabel?.toLowerCase(),
              otpSent:
                selectedModeLabel?.toLowerCase() ===
                OTP_VERIFICATION_MEDIUM.EMAIL_VERIFICATION_MEDIUM
                  ? emailId
                  : `${countryCode} ${mobileNumber}`,
            },
          },
          isDynamicUrl: false,
        };
        navigatePageUtils(urls.verificationPage, navigateDetails);
      } else if (
        response &&
        response?.status === "ERROR" &&
        response?.statusCode === 403 &&
        response?.message === "USER_BLOCKED"
      ) {
        setToastMessage(t(AppConstant.ERROR.ACCOUNT_BLOCKED_MESSAGE));
        setModalCtaAction("Okay");
      } else {
        setToastMessage(t(response?.message));
        setModalCtaAction("");
      }
      setModalOpen(true);
    } else {
      setNextCtaEnable(false);
      // setShowToast(true);
      // setIsSuccess(false);
      setToastMessage(t(AppConstant.ERROR.OTP_SENT_FAILED));
      setModalOpen(true);
    }
  };

  const handleToastClose = () => {
    setShowToast(false);
    // if (isSuccess) {
    //   // const navigateDetails = {
    //   //   stateParams: {
    //   //     sourcePage: pageNames.SignIn,
    //   //     nextCta: t(AppConstant.AUTHENTICATION_PAGE.SIGN_IN_CTA),
    //   //     formData: {
    //   //       mobile: mobileNumber,
    //   //       country: countryCode,
    //   //       email: emailId,
    //   //       mode: selectedModeLabel?.toLowerCase(),
    //   //       otpSent:
    //   //         selectedModeLabel?.toLowerCase() ===
    //   //         OTP_VERIFICATION_MEDIUM.EMAIL_VERIFICATION_MEDIUM
    //   //           ? emailId
    //   //           : `${countryCode} ${mobileNumber}`,
    //   //     },
    //   //   },
    //   //   isDynamicUrl: false,
    //   // };
    //   // navigatePageUtils(urls.verificationPage, navigateDetails);
    // } else {
    //   setShowToast(false);
    // }
  };

  useEffect(() => {
    if (
      selectedModeLabel?.toLowerCase() ===
      OTP_VERIFICATION_MEDIUM.EMAIL_VERIFICATION_MEDIUM
    ) {
      setMobileNumber('');
    } else {
      setEmailId('');
    }
  }, [selectedVerificationMedium]);

  const switchToSignUp = () => {
    const navigateDetails = {
      isDynamicUrl: false,
      stateParams: {
        pathName: statePathName,
      },
    };
    navigatePageUtils(urls.signUpPage, navigateDetails);
  };

  const handleVerificationMediumChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const verificationMedium = (event.target as HTMLInputElement).value;
    setSelectedVerificationMedium(verificationMedium);
  };

  useEffect(() => {
    if (
      selectedVerificationMedium &&
      !radioGroupValidation(selectedVerificationMedium) &&
      (selectedModeLabel?.toLowerCase() ===
      OTP_VERIFICATION_MEDIUM.EMAIL_VERIFICATION_MEDIUM
        ? emailId && !emailValidation(emailId)
        : mobileNumber &&
          !mobileValidation(mobileNumber, mobileLength) &&
          !mobileStartZeroValidation(mobileNumber) &&
          countryCode &&
          !countryCodeValidation(countryCode))
    ) {
      setNextCtaEnable(true);
    } else {
      setNextCtaEnable(false);
    }
  }, [mobileNumber, selectedVerificationMedium, countryCode, emailId]);

  const onLogoClick = () => {
    const navigateDetails = {
      isDynamicUrl: false,
    };
    navigatePageUtils(urls.homePage, navigateDetails);
  };

  const handleHelpSupport = () => {
    const navigateDetails = {
      isDynamicUrl: false,
    };
    navigatePageUtils(urls.helpSupport, navigateDetails);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleCountryCodeChange = async (value: any) => {
    setCountryCode(value);
  };
  const { AuthenticationBgIcon } = ImageConstant.SVG_ICONS;

  const onModalCtaPress = () => {
    setModalOpen(false)
    setToastMessage('')
    setModalCtaAction('')
    const navigateDetails = {
      isDynamicUrl: false,
    };
    navigatePageUtils(urls.helpSupport, navigateDetails);
  }

  return (
    <div className={styles.signInPage}>
      <div className={styles.authBg}>
        <AuthenticationBgIcon />
      </div>
      <div className='cust-container'>
        {/* <Toast showToast={showToast} handleToastClose={handleToastClose} message={'OTP Sent Successfully'} /> */}
        <div className={styles.signUplogo} onClick={onLogoClick}>
          <img
            src={ImageConstant.SOURCE.ShalinaMedspaceLogo}
            alt='logo'
            className={styles.logo}
          />
        </div>
      </div>

      {!returnData && !samlAuthenticalLoading && (
        <div className={styles.signInWrapper}>
          <p className={styles.title}>
            {t(AppConstant.AUTHENTICATION_PAGE.SIGN_IN_CTA)}
          </p>
          <div className={styles.signInFormCol}>
            {selectedModeLabel?.toLowerCase() ===
            OTP_VERIFICATION_MEDIUM.EMAIL_VERIFICATION_MEDIUM ? (
              <Input
                placeHolderText={t(AppConstant.PROFILE.ENTER_YOUR_EMAIL)}
                formControlVariant={'standard'}
                labelText={t(AppConstant.AUTHENTICATION_PAGE.EMAIL_TEXT)}
                inputId={'email'}
                inputVariant={'outlined'}
                isRequired={true}
                isError={emailValidation(emailId)}
                errorMessage={t(AppConstant.ERROR.ENTER_VALID_EMAIL)}
                handleChange={(e: eventType) => setEmailId(e.target.value)}
              />
            ) : (
              <MobileInput
                placeHolderText={t(AppConstant.PROFILE.ENTER_MOBILE_NUMBER)}
                defaultCountryCode={countryCode}
                countryValue={countryCode}
                formControlVariant='standard'
                labelText={t(
                  AppConstant.AUTHENTICATION_PAGE.MOBILE_NUMBER_TEXT
                )}
                inputId='mobileNumbar'
                inputVariant='outlined'
                isRequired={true}
                dropdownVariant='outlined'
                dropdownOptions={countryList}
                isError={
                  mobileStartZeroValidation(mobileNumber) ||
                  mobileValidation(mobileNumber, mobileLength)
                }
                countryCodeError={countryCodeValidation(countryCode)}
                errorMessage={
                  mobileStartZeroValidation(mobileNumber)
                    ? t(AppConstant.ERROR.MOBILE_VALIDATION_FOR_START_ZERO)
                    : t(AppConstant.ERROR.ENTER_VALID_MOBILE)
                }
                handleChange={(e: eventType) => setMobileNumber(e.target.value)}
                handleCountryCodeChange={(e: any) => {
                  mobileStartZeroValidation(mobileNumber);
                  mobileValidation(mobileNumber, mobileLength);
                  handleCountryCodeChange(e.target.value);
                }}
                maxMobileLength={mobileLength}
                countryFlag={countryFlag}
              />
            )}
          </div>
          <div className={styles.signInFormCol}>
            <RadioGroupComponent
              formControlVariant={'standard'}
              labelText={t(
                AppConstant.AUTHENTICATION_PAGE.GET_VERIFICATION_CODE_ON_TEXT
              )}
              inputId={'verificationCode'}
              isRequired={true}
              radioGroupObj={contactModes}
              selectedValue={selectedVerificationMedium}
              handleRadioChange={handleVerificationMediumChange}
              isError={radioGroupValidation(selectedModeLabel?.toLowerCase())}
              errorMessage={t(AppConstant.ERROR.ENTER_VALID_MODE)}
            />
          </div>
          <ButtonComponent
            variant='outlined'
            buttonText={t(AppConstant.AUTHENTICATION_PAGE.CONTINUE_CTA)}
            onPress={handleSignIn}
            disabled={!nextCtaEnable}
          />
          <div className={`${styles.toggleLogin} ${styles.helpLink}`}>
            <span>{t(AppConstant.COMMON.FACING_ISSUE_TEXT)}</span>
            <HyperLink
              handleClick={handleHelpSupport}
              hyperText={t(AppConstant.HEADER.HELP_SUPPORT_MENU)}
              disabled={false}
            />
          </div>

          <div className={styles.divider}>
            <HorizontalDivider />
          </div>
          <div className={styles.toggleLogin}>
            <span>
              {t(AppConstant.AUTHENTICATION_PAGE.DIDNOT_HAVE_ACCOUNT_TEXT)}
            </span>
            <HyperLink
              handleClick={switchToSignUp}
              hyperText={t(AppConstant.AUTHENTICATION_PAGE.SIGN_UP_CTA)}
              disabled={false}
            />
          </div>
        </div>
      )}
      {samlAuthenticalLoading && (
        <>
          <div className={styles.loaderWrapper}>
            {t(AppConstant.COMMON.AUTHENTICATION_LOADER_TEXT)}
          </div>
        </>
      )}
      {authenticationLoading && <CircularLoader size={50} thickness={4} />}
      <Toast
        showToast={showToast}
        isSuccess={isSuccess}
        message={toastMessage}
        subMessage={toastSubMessage}
        handleToastClose={handleToastClose}
      />

      <Modal modalOpen={modalOpen} handleModalClose={handleModalClose}>
        <div className={styles.modalWrap}>
            <h2 className={styles.title}>
              {t(AppConstant.ERROR.ERROR_TEXT)}
            </h2>
          <button onClick={handleModalClose} className={styles.closeCta}>
            &times;
          </button>
          <span>{toastMessage}</span>
          {modalCtaAction && modalCtaAction !== '' && <div className={styles.modalCtaWrap}>
            <button onClick={onModalCtaPress}>
              {modalCtaAction}
            </button>
          </div>}
        </div>
      </Modal>
    </div>
  );
};
